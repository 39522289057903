import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library';
import React from 'react';
import { colors } from '../../../modules/colors';
import { PrimaryText, ResponsiveSectionHeader } from '../../typography';

const SectionStyle = createOwnUpStyle({
  backgroundColor: colors.MINT,
  marginLeft: 'min(calc((1280px - 100vw) / 2), 0px)',
  marginRight: 'min(calc((1280px - 100vw) / 2), 0px)',
  width: '100vw'
});

const ComplianceContentStyle = createOwnUpStyle({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '130px 60px',
  maxWidth: 1280,
  margin: 'auto',
  variants: {
    medium: {
      padding: 60
    },
    smallAndDown: {
      padding: 40
    }
  }
});

const ComplianceContentWrapper = createOwnUpComponent(OwnUpBox, ComplianceContentStyle);

const ListItemStyle = createOwnUpStyle({
  marginBottom: 15,
  '&:last-child': {
    marginBottom: 0
  }
});

const ListItem = createOwnUpComponent('li', ListItemStyle);

const complianceItems = [
  'Leads are delivered in a RESPA and SAFE Act compliant manner.',
  'Own Up is compliant with all bank-level vendor management requirements.',
  'Own Up is licensed and regulated as a mortgage broker (NMLS #1450805) in the states it operates in.'
];

const UlStyle = createOwnUpStyle({
  maxWidth: 700,
  paddingInlineStart: 20
});

const StyledUl = createOwnUpComponent('ul', UlStyle);

const ComplianceList = () => (
  <StyledUl>
    {complianceItems.map((item, i) => (
      <ListItem key={i}>
        <PrimaryText>{item}</PrimaryText>
      </ListItem>
    ))}
  </StyledUl>
);

const Section = createOwnUpComponent('section', SectionStyle);

export const Compliance = () => (
  <Section aria-label="compliance">
    <ComplianceContentWrapper>
      <ResponsiveSectionHeader>Compliance</ResponsiveSectionHeader>
      <ComplianceList />
    </ComplianceContentWrapper>
  </Section>
);
